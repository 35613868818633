/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";

import { MachineLearning } from "capacitor-elinkx-machine-learning";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { EventCode, ScanType } from "@elx-element/common/enums";
import { getConfigurationBoolValue } from "@elx-element/common/envconf";
import { dispatchWrapperBackButtonClickEvent } from "@elx-element/common/events/dispatchers";
import { registerWrapperBackButtonClickDefaultListener } from "@elx-element/common/events/listeners";
import { initiateNfcWrite } from "@elx-element/common/events/nfc";
import { dispatchScannerEvent } from "@elx-element/common/events/scanner";
import { getWrapperBackButtonDefaultListenerActive } from "@elx-element/common/storage";

import { App } from "@capacitor/app";
import { PluginListenerHandle } from "@capacitor/core/types/definitions";

import store from "../store";
import { selectPlatformIsActive, selectRoutes } from "../store/main/selectors";

const debug = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_DEBUG_LOG");

// handler pro listener události stisknutí hw tlačítke zpět
let backButtonListenerHandler: Promise<PluginListenerHandle>;

/**
 * Hook lze volat pouze jednou při spuštění aplikace, v opačném případě by mohlo dojít k opakovanému vytvoření listnere pro stisknutí hw tlačítka zpět.
 * @param enabled
 */
export const useHardwareBackButton = (enabled: boolean) => {
  const history = useHistory();
  const routes = useSelector(selectRoutes);
  const platformActive = useSelector(selectPlatformIsActive);

  /**
   * Obsluha stisknutí tlačítka zpět v mobilním zařízení (wrapper).
   * @param routes
   */
  const handleBackButtonClick = () => {
    if (getWrapperBackButtonDefaultListenerActive() === true) {
      history.push(routes.find(x => x.default)?.route ? `/${routes.find(x => x.default)?.route}` : "/");
    }
  };

  useEffect(() => {
    if (enabled) {
      // Listener pro tlačítko zpět na mobilním zařízení
      registerWrapperBackButtonClickDefaultListener(handleBackButtonClick);
    }
  }, [enabled]);

  useEffect(() => {
    if (platformActive && enabled && backButtonListenerHandler == null) {
      // obsluha mobilního tlačítka zpět, aktuálně toto pravidlo potlačuje chování mobilního tlačítka zpět
      backButtonListenerHandler = App.addListener("backButton", (data: any) => {
        if (debug) {
          console.debug("[Element Wrapper]: Back button event called.", JSON.stringify(data));
        }

        // obsluha tlačítka zpět v situaci kdy je zapnuto skenování
        MachineLearning.isCameraActive().then((result: { value: boolean }) => {
          /**
           * Pokud je aktivní UI kamery nebo NFC skeneru, eventem provedu zrušení skenování.
           */
          if (
            result.value ||
            (store.getState().main.scannerSettings?.type === ScanType.nfcMessage &&
              store.getState().main.scannerSettings?.active)
          ) {
            // Vyvolání události zrušení skenování
            dispatchScannerEvent({ type: EventCode.scanAborted, scan: undefined });
          } else if (store.getState().main.nfcWriterSettings?.active) {
            /**
             * V případě že je aktivní UI pro obsluhu NFC writeru, vyvolá událost pro zrušení nfc zápisu.
             */
            initiateNfcWrite({
              active: false,
              data: "",
              name: "",
              makeReadOnly: false,
            });
          } else {
            /*
            Tlačítko zpět je obsluhováno na dvou úrovních - defaultListener (container) nebo customListener (modul).
            Jestliže připojený modul má zaregistrovaný customListener pro obsluhu této události, pak je potřeba potlačit chování
            defaultListeneru (container). K tomu slouží informace v sessionStorage uložišti s názvem BlockBackButtonDefaultListener.
          */
            dispatchWrapperBackButtonClickEvent(debug);
          }
        });
      });
    }
  }, [platformActive, enabled]);
};
