/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";

import { useSnackbar } from "notistack";
import { useAuth } from "react-oidc-context";
import { useDispatch } from "react-redux";

import IconButton from "@mui/material/IconButton/IconButton";

import { getConfigurationStringValue } from "@elx-element/common/envconf";
import { registerNotificationListener } from "@elx-element/common/events/listeners";
import { NotificationEventModel } from "@elx-element/common/events/types";
import { format } from "@elx-element/common/utils";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";

import { mdiCertificateOutline, mdiClose, mdiLightbulbOutline } from "@mdi/js";

import { setOpenErrorDetailReference } from "../store/main/slice";

import useStyles from "../components/app/styles";

import useTexts from "./useTexts";

import { WebContainerApplication } from "../enums";
import History from "../history";

export const useNotificationEvents = (enabled: boolean) => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const snackbar = useSnackbar();
  const texts = useTexts();
  const { classes } = useStyles();
  const { isAuthenticated } = auth;

  /**
   * Funkce pro zobrazení chyby v aplikaci
   * @param eventDetail
   */
  const showError = (eventDetail: NotificationEventModel) => {
    const guid = Date.now().toString();
    // notifikace se zobrazují pouze autorizovanému uživateli.
    if (isAuthenticated) {
      snackbar.enqueueSnackbar(
        <div>
          {format(texts.ERROR_OCCURED, eventDetail.application)}

          {/* tímto zápisem předejdu chybám, "Objects are not valid as a React Child", v případě, že je v eventDetail?.message obsažen objekt */}
          <div className="message">{`${eventDetail?.message}`}</div>
        </div>,
        {
          className: classes.errorNotification,
          key: guid,
          variant: eventDetail?.type,
          autoHideDuration: null,
          action: (
            <>
              {eventDetail.errorReference && (
                <IconButton
                  color="inherit"
                  title={texts.RESOLVE}
                  onClick={() => {
                    snackbar.closeSnackbar(guid);
                    dispatch(setOpenErrorDetailReference(eventDetail.errorReference));
                  }}
                >
                  <BaseIcon data={mdiLightbulbOutline} />
                </IconButton>
              )}
              {eventDetail.application === WebContainerApplication.licenses && (
                <IconButton
                  color="inherit"
                  title={texts.RESOLVE}
                  onClick={() => {
                    snackbar.closeSnackbar(guid);
                    History.push(
                      getConfigurationStringValue(window.env.webcontainer, "MODULE_LICENSE_PROBLEM_SOLVING_URL")
                    );
                  }}
                >
                  <BaseIcon data={mdiCertificateOutline} />
                </IconButton>
              )}
              <IconButton
                aria-label="close"
                color="inherit"
                title={texts.CLOSE}
                onClick={() => snackbar.closeSnackbar(guid)}
              >
                <BaseIcon data={mdiClose} />
              </IconButton>
            </>
          ),
        }
      );
    }
  };

  /**
   * Funkce pro zobrazení notifikace v aplikaci
   * @param eventDetail
   */
  const showNotification = (eventDetail: NotificationEventModel) => {
    const guid = Date.now().toString();
    snackbar.enqueueSnackbar(
      <div className={classes.notificationDetail}>
        <div>{eventDetail?.message}</div>
        {eventDetail?.description && <div className="description">{eventDetail?.description}</div>}
      </div>,
      {
        key: guid,
        variant: eventDetail?.type,
        anchorOrigin: eventDetail?.position,
        autoHideDuration: eventDetail?.useCloseButton ? null : undefined,
        action: eventDetail?.useCloseButton ? (
          <>
            {eventDetail.application === WebContainerApplication.licenses && (
              <IconButton
                color="inherit"
                title={texts.RESOLVE}
                onClick={() => {
                  snackbar.closeSnackbar(guid);
                  History.push(
                    getConfigurationStringValue(window.env.webcontainer, "MODULE_LICENSE_PROBLEM_SOLVING_URL")
                  );
                }}
              >
                <BaseIcon data={mdiCertificateOutline} />
              </IconButton>
            )}
            <IconButton
              aria-label="close"
              color="inherit"
              title={texts.CLOSE}
              onClick={() => snackbar.closeSnackbar(guid)}
            >
              <BaseIcon data={mdiClose} />
            </IconButton>
          </>
        ) : undefined,
      }
    );
  };

  useEffect(() => {
    if (enabled) {
      registerNotificationListener(event => showError(event), showNotification);
    }
  }, [enabled]);
};
