/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";

import { useDispatch } from "react-redux";

import { apiMan } from "@elx-element/common/apiClient/apiMan";
import { Notification } from "@elx-element/common/enums";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { registerMessageListener } from "@elx-element/common/events/listeners";
import { MessageEventModel, NotificationEventModel } from "@elx-element/common/events/types";

import { INewMessageModel } from "../types";
import { WebContainerClient } from "../types.generated";

import { addMessages, addNotifications } from "../store/message/slice";

import useAbortController from "./useAbortController";
import useTexts from "./useTexts";

/**
 * Systémové norifikace a zprávy - ikony zvonečku a obálky
 * @param enabled
 */
export const useElementMessages = (enabled: boolean) => {
  const dispatch = useDispatch();
  const texts = useTexts();
  const abortController = useAbortController();
  /**
   * Load notifikací
   * @param client
   * @param dispatch
   */
  const loadNotifications = async () => {
    const notifications = await apiMan(
      new WebContainerClient({ abortSignal: abortController.signal }).notificationAll(1000, 0)
    );
    if (notifications) {
      dispatch(addNotifications(notifications));
    }
  };

  /**
   * Load zpráv
   * @param client
   * @param dispatch
   */
  const loadMessages = async () => {
    const messages = await apiMan(
      new WebContainerClient({ abortSignal: abortController.signal }).messageAll(1000, 0, undefined, undefined, false)
    );
    if (messages) {
      dispatch(addMessages(messages));
    }
  };

  /**
   * Handler pro odeslání zprávy z modulu.
   * Po odchycení eventu a předání dat se zpráva odešle k dalšímu zpracování v WebContainerApi.
   * @param eventDetail
   * @param client
   * @param sendNotificationText
   */
  const handleMessageSend = async (eventDetail: MessageEventModel) => {
    const messageModel: INewMessageModel = {
      sender: eventDetail.sender,
      recipient: eventDetail.recipient,
      description: eventDetail.description,
      action: eventDetail.action,
      title: eventDetail.title,
      read: false,
      processed: false,
      severity: eventDetail.severity,
    };

    let success = true;
    try {
      await apiMan(new WebContainerClient({ abortSignal: abortController.signal }).message2(messageModel));
    } catch {
      success = false;
    }
    if (success) {
      dispatchNotificationEvent(new NotificationEventModel(texts.MESSAGE_SEND, Notification.success));
    }
  };

  useEffect(() => {
    if (enabled) {
      registerMessageListener(event => handleMessageSend(event));
      loadMessages();
      loadNotifications();
    }
  }, [enabled]);
};
