/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getConfigurationBoolValue, getConfigurationStringValue } from "@elx-element/common/envconf";
import { registerPlayAudioListener } from "@elx-element/common/events/listeners";
import { AudioEventType, AudioType } from "@elx-element/common/events/types";

import store from "../store";
import { selectAudioBuffer } from "../store/main/selectors";
import { setAudioBuffer } from "../store/main/slice";

const debug = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_DEBUG_LOG");

/**
 * Obsluha pro zvukové události v prostředí elementu.
 * Hook lze volat pouze jednou při spuštění aplikace.
 * @param enabled
 */
export const useSoundEvents = (enabled: boolean) => {
  const dispatch = useDispatch();

  // pokud v bufferu existuje záznam, pak to znamená že probíhá analýza
  const audioBuffer = useSelector(selectAudioBuffer);

  // rutina pro zaznamenání zvuku do bufferu
  const handleSound = (params: CustomEventInit<AudioEventType>) => {
    if (params?.detail) {
      dispatch(
        setAudioBuffer([
          ...store.getState().main.audioBuffer,
          // následující zápis je vyžadován z důvodu zachování kompatibility s common knihovnou ve verzi 3.3
          params.detail.type ? params.detail : { type: params.detail as unknown as AudioType, priority: undefined },
        ])
      );
    }
  };

  // funkce pro stanovení priority zvuku. Pokud není zadán, je použit defaultní priorita.
  const getPriority = (sound: AudioEventType) => {
    if (sound.priority !== undefined) return sound.priority;
    switch (sound.type) {
      case "success":
        return 0;
      case "success2":
        return 1000;
      case "info":
        return 2000;
      case "warning":
        return 3000;
      case "fail":
        return 4000;
      default:
        return 0;
    }
  };

  // vyhodnocení a přehrání zvuku s nejvyšší prioritou, následné vyčištění bufferu
  const playSound = () => {
    if (store.getState().main.audioBuffer.length > 0) {
      if (debug) {
        console.debug("[Element] AudioBuffer content", store.getState().main.audioBuffer);
      }
      const highestPriorityRecord = store
        .getState()
        .main.audioBuffer.reduce((highest: AudioEventType, current: AudioEventType) => {
          // Porovnáme priority a vrátíme záznam s vyšší prioritou
          if (getPriority(current) > getPriority(highest)) {
            return current;
          }
          return highest;
        });
      if (debug) {
        console.debug("[Element] Audio highest priority record", highestPriorityRecord);
      }

      // vyčístíme buffer pro další zvuk
      dispatch(setAudioBuffer([]));

      // přehrajeme zvuk s nejvyšší prioritou
      switch (highestPriorityRecord.type) {
        case "success":
          playAudio(getConfigurationStringValue(window.env[process.env.REACT_APP_NAME!], "SUCCESS_AUDIO_SOURCE"));
          break;
        case "success2":
          playAudio(getConfigurationStringValue(window.env[process.env.REACT_APP_NAME!], "SUCCESS_2_AUDIO_SOURCE"));
          break;
        case "warning":
          playAudio(getConfigurationStringValue(window.env[process.env.REACT_APP_NAME!], "WARNING_AUDIO_SOURCE"));
          break;
        case "fail":
          playAudio(getConfigurationStringValue(window.env[process.env.REACT_APP_NAME!], "FAIL_AUDIO_SOURCE"));
          break;
        case "info":
          playAudio(getConfigurationStringValue(window.env[process.env.REACT_APP_NAME!], "INFO_AUDIO_SOURCE"));
          break;
        default:
          break;
      }
    }
  };

  const playAudio = (data: string) => {
    const audio = new Audio(data);
    audio.play();
  };

  useEffect(() => {
    if (enabled) {
      registerPlayAudioListener(handleSound);
    }
  }, [enabled]);

  useEffect(() => {
    let refTimeoutAnalysis: NodeJS.Timeout | undefined;
    // Funkce pro zachycení zvukového signálu, uložení do bufferu pro následné vyhodnocení priority a přehrátí zvuku.
    // Při prvním výskytu zvukové události spouštím časovač okna pro vyhodnocování priority.
    if (enabled && audioBuffer.length > 0) {
      refTimeoutAnalysis = setTimeout(playSound, 250); // defaultně vyhodnocujeme zvukové notifikace v okně 250ms.
    }
    return () => {
      if (refTimeoutAnalysis !== undefined) {
        clearTimeout(refTimeoutAnalysis);
      }
    };
  }, [enabled, audioBuffer]);
};
