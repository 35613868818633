import React, { useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useElementContext } from "@elx-element/common";
import { NetworkStatus, Notification } from "@elx-element/common/enums";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { NotificationEventModel } from "@elx-element/common/events/types";
import { RegisteredModule } from "@elx-element/common/types";
import { format } from "@elx-element/common/utils";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";

import { mdiChevronLeft, mdiHelpCircleOutline, mdiMenu, mdiViewDashboardOutline } from "@mdi/js";

import {
  selectActiveModule,
  selectCulture,
  selectMainMenuOpen,
  selectNetworkStatus,
  selectRegisteredModules,
} from "../../store/main/selectors";
import { setInfoPopupOpen, setMainMenuOpen } from "../../store/main/slice";

import useTexts from "../../hooks/useTexts";

import useNavigationStyles from "./styles";

const Navigation = () => {
  const dispatch = useDispatch();
  const menuOpen = useSelector(selectMainMenuOpen);
  const registeredModules = useSelector(selectRegisteredModules);
  const { classes, cx } = useNavigationStyles();
  const theme = useTheme();
  const texts = useTexts();
  const openApp = useSelector(selectActiveModule); // reference na otevřený modul
  const culture = useSelector(selectCulture);
  const isPrinting = useMediaQuery("print");
  const isTabletView = useMediaQuery(theme.breakpoints.down("md")) && !isPrinting;
  const isDesktopView = !isTabletView;
  const networkStatus = useSelector(selectNetworkStatus);
  const isOffline = networkStatus === NetworkStatus.offline;
  const { checkTokenAnyRoleExists } = useElementContext();

  const handleMainMenuOpen = () => {
    dispatch(setMainMenuOpen(true));
  };

  const handleMainMenuClose = () => {
    dispatch(setMainMenuOpen(false));
  };

  // Pokud se jedná o externí modul, k němuž chybí konfigurace, modul je blokován.
  const handleNavigationClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    m: RegisteredModule | undefined
  ) => {
    if (!isOffline) {
      if (isTabletView) {
        handleMainMenuClose();
      }
      if (m && m.licenseExpired()) {
        event.preventDefault();
      } else if (m && m.configExists === false && m.externalModule === true) {
        event.preventDefault();
        dispatchNotificationEvent(
          new NotificationEventModel(format(texts.CONFIG_FILE_MISSING, m.moduleId), Notification.warning)
        );
      }
    }
  };

  const handleInfoPopupOpen = () => {
    if (menuOpen) {
      dispatch(setMainMenuOpen(false));
    }
    dispatch(setInfoPopupOpen(true));
  };

  // Vrací data pro hlavní menu, seřazená podle property Order. Pokud řazení není definováno, řadíme nakonec (pozice 999).
  const getOrderedData = () => [...registeredModules].sort((a, b) => (a.order ?? 999) - (b.order ?? 999));
  const memoizedOrderedData = useMemo(() => getOrderedData(), [registeredModules]);

  return (
    <Drawer
      id="wC-navigation"
      variant={isTabletView ? "temporary" : "permanent"}
      anchor={isTabletView ? "top" : undefined}
      onClose={isTabletView ? handleMainMenuClose : undefined}
      classes={{
        paper: isTabletView
          ? cx(classes.drawerPaperMobilePlatform, !menuOpen && classes.drawerPaperCloseMobilePlatform)
          : cx(classes.drawerPaper, !menuOpen && classes.drawerPaperClose),
      }}
      open={menuOpen}
    >
      <div className={cx(classes.mainNavButtonContainer, menuOpen ? classes.mainNavButtonContainerFlexEnd : undefined)}>
        {/* Ikon buttony pro ovládání zobrazení hlavního menu */}
        {!menuOpen && (
          <IconButton onClick={handleMainMenuOpen} className={classes.mainNavButton}>
            <BaseIcon data={mdiMenu} />
          </IconButton>
        )}
        {menuOpen && (
          <IconButton onClick={handleMainMenuClose} className={classes.mainNavButton}>
            <BaseIcon data={mdiChevronLeft} />
          </IconButton>
        )}
      </div>

      <List classes={{ padding: classes.scrolledList }}>
        <NavLink
          to="/dashboard"
          onClick={event => handleNavigationClick(event, undefined)}
          className={cx(classes.navLink, !openApp?.route ? classes.navLinkActive : undefined)}
        >
          <Tooltip title={menuOpen ? "" : "Dashboard"} aria-label="dashboard" placement="right">
            <ListItem button>
              <ListItemIcon>
                <BaseIcon data={mdiViewDashboardOutline} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" color="secondary" />
            </ListItem>
          </Tooltip>
        </NavLink>
        {memoizedOrderedData.length > 0 && (
          <>
            <Divider />
            {menuOpen && (
              <ListSubheader className={classes.menuListTitle} inset>
                {texts?.ACTIVE_MODULES}
              </ListSubheader>
            )}
            {memoizedOrderedData
              .filter(
                x =>
                  ((isTabletView && x.allowOnMobile) || isDesktopView) &&
                  (!x.requiredPermission || checkTokenAnyRoleExists(x.requiredPermission!))
              )
              .map(m => (
                <NavLink
                  key={m.moduleId}
                  to={`/${m.route ?? m.moduleId}`}
                  onClick={event => handleNavigationClick(event, m)}
                  className={cx(
                    classes.navLink,
                    openApp?.route === m.route ? classes.navLinkActive : undefined,
                    isOffline || m.licenseExpired() ? classes.notAllowed : undefined
                  )}
                >
                  <Tooltip
                    title={menuOpen ? "" : m.getLocalizedName(culture)}
                    aria-label={m.tileIcon}
                    placement="right"
                    classes={{ tooltip: classes.tooltipPopper }}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <BaseIcon data={m.tileIcon ?? ""} />
                      </ListItemIcon>
                      <ListItemText primary={m.getLocalizedName(culture)} />
                    </ListItem>
                  </Tooltip>
                </NavLink>
              ))}
          </>
        )}
      </List>
      <Divider />

      {window.env.webcontainer.SHOW_TENANT_CONTENT && (
        <Tooltip title={texts.ABOUT_US}>
          <IconButton aria-label="infoBtn" className={classes.infoBtn} onClick={handleInfoPopupOpen}>
            <BaseIcon data={mdiHelpCircleOutline} />
            <Typography variant="body1" marginLeft={theme.spacing(2)}>
              {texts.ABOUT_US}
            </Typography>
          </IconButton>
        </Tooltip>
      )}
    </Drawer>
  );
};

export default Navigation;
