/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";

import { ModuleInterfaceDirectionFlag, NetworkStatus } from "@elx-element/common/enums";
import { getConfigurationBoolValue, getConfigurationNumberValue } from "@elx-element/common/envconf";
import { dispatchEvent } from "@elx-element/common/events/dispatchers";
import { registerModuleSwitchListener } from "@elx-element/common/events/listeners";
import { SwitchModuleEventModel } from "@elx-element/common/events/types";
import { throwSpecificError } from "@elx-element/common/logger";
import { getStoredNetworkStatus } from "@elx-element/common/storage";
import { RegisteredModule } from "@elx-element/common/types";

import store from "../store";

import { WebContainerErrors } from "../enums";
import History from "../history";

export const useModuleSwitchEvents = (enabled: boolean) => {
  /**
   * Funkce pro přepnutí aktivního modulu pomoci události SwithModuleEvent.
   * Pokud komunikace není definovaná v rozhraní modulů, zobrazí se chyba.
   * Probíhá přepnutí na požadovaný modul a poté je vykonána požadovaná událost.
   * V offline režimu je přepínání modulů zakázáno! Změna se nevykoná.
   * @param eventDetail Data předána mezi aplikacemi
   * @param registeredModules Seznam registrovaných modulů
   * @param token token pro test role
   */
  const handleModuleSwitch = (eventDetail: SwitchModuleEventModel, registeredModules: Array<RegisteredModule>) => {
    if (getStoredNetworkStatus() === NetworkStatus.online) {
      // Test na existenci interface
      const targetModule = registeredModules.find(x => x.moduleId === eventDetail.targetModuleId);
      if (targetModule?.interface?.events?.find(x => x.eventName === eventDetail.eventName && x.confirmed)) {
        // Defaultní timeout pro připojení modulu a spuštění události
        let moduleConnectTimeout = getConfigurationNumberValue(window.env.webcontainer, "MODULE_LOAD_TIMEOUT");

        // validace dat - v případě neúspěchu nedojde k přesměrování na nový modul
        const eventDefinition = targetModule.interface.events.find(
          x =>
            x.moduleId === eventDetail.initiatorModuleId &&
            x.directionFlag === ModuleInterfaceDirectionFlag.in &&
            x.confirmed
        );
        let validationResult = true;
        if (eventDefinition && eventDefinition.objectDefinition) {
          eventDefinition.objectDefinition.forEach(x => {
            // test na existenci property
            if (eventDetail.data[x.name] === undefined) {
              validationResult = validationResult && false;
            }
            // test na formát property
            if (validationResult) {
              switch (x.type.toLowerCase()) {
                case "number":
                  validationResult = !Number.isNaN(eventDetail.data[x.name]);
                  break;
                case "datetime":
                  validationResult = !Number.isNaN(Date.parse(eventDetail.data[x.name]));
                  break;
                case "object":
                  validationResult = typeof eventDetail.data[x.name] === "object";
                  break;
                case "boolean":
                  validationResult = typeof eventDetail.data[x.name] === "boolean";
                  break;
                default:
                  // string
                  validationResult = typeof eventDetail.data[x.name] === "string";
                  break;
              }
            }
          });
        }

        if (validationResult) {
          // požadavek na přeroutování modulu je validní

          // Routing na požadovaný modul pokud již není otevřený
          if (History.location.pathname !== `/${targetModule.route}`) {
            History.push(`/${targetModule.route}`);
          } else {
            // modul již je načtený a není potřeba čekat.
            moduleConnectTimeout = 0;
          }

          // Pokud existují data, pak se má po připojení modulu vygenerovat událost.
          if (eventDetail.data !== undefined) {
            // Čeká se na připojení modulu do stránky
            setTimeout(() => {
              // Pokud se modul podaří načíst v definovaném limitu, vytvoří se event. Pozná se to podle existence elementu s id "{id modulu}content"
              if (document.getElementById(`${eventDetail.targetModuleId}content`)) {
                dispatchEvent(
                  `${eventDetail.targetModuleId.toLocaleUpperCase()}:${eventDetail.eventName.toLocaleUpperCase()}`,
                  eventDetail.data,
                  getConfigurationBoolValue(window.env.webcontainer, "ENABLE_DEBUG_LOG")
                );
              } else {
                // Modul se nepodařilo spustit v požadovaném časovém limitu
                throwSpecificError(
                  WebContainerErrors.executeActionFailed,
                  `Executing an action failed. Module '${eventDetail.targetModuleId}' wasn't loaded in the required time limit.`,
                  undefined
                );
              }
            }, moduleConnectTimeout);
          }
        } else {
          // Data eventu nesplňují předepsaný formát definovaný v konfiguračním souboru interface.json
          throwSpecificError(
            WebContainerErrors.executeActionFailed,
            "Unsupported communication type. Event data does not have the correct data type.",
            undefined
          );
        }
      } else {
        // Zobrazení chyby. Nepodporovaný typ komunikace
        throwSpecificError(
          WebContainerErrors.unsupportedCommunicationType,
          `Unsupported communication type. Event '${eventDetail.eventName}' wasn't confirmed.`,
          undefined
        );
      }
    }
  };

  useEffect(() => {
    if (enabled) {
      registerModuleSwitchListener(event => handleModuleSwitch(event, store.getState().main.registeredModules));
    }
  }, [enabled]);
};
