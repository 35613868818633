/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";

import { useSelector } from "react-redux";

import { useMediaQuery, useTheme } from "@mui/material";

import { getConfigurationBoolValue } from "@elx-element/common/envconf";
import { dispatchContentPositionChangeEvent } from "@elx-element/common/events/dispatchers";
import { storeContentPosition } from "@elx-element/common/storage";

import { selectMainMenuOpen } from "../store/main/selectors";

const debug = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_DEBUG_LOG");

/**
 * Funkce pro obsluhu změny velikosti obsahové plochy
 * @param enabled
 */
export const useResizeListener = () => {
  const theme = useTheme();
  const isPrinting = useMediaQuery("print");
  const isTabletView = useMediaQuery(theme.breakpoints.down("md")) && !isPrinting;
  const isDesktopView = !isTabletView;
  const mainMenuOpen = useSelector(selectMainMenuOpen);
  /**
   * Metoda pro uchovávání koordinátů obsahové plochy.
   * @param isDesktopView
   */
  const handleStoreContentPosition = () => {
    setTimeout(() => {
      const contentPosition = {
        offsetLeft: isDesktopView ? document.getElementById("wC-navigation")?.offsetWidth ?? 0 : 0,
        offsetTop: document.getElementById("wC-header")?.offsetHeight ?? 0,
      };
      storeContentPosition(contentPosition);
      dispatchContentPositionChangeEvent(contentPosition, debug);
    }, 0);
  };

  useEffect(() => {
    handleStoreContentPosition();
  }, [mainMenuOpen]);

  useEffect(() => {
    window.removeEventListener("resize", handleStoreContentPosition);
    window.addEventListener("resize", handleStoreContentPosition);
  }, []);
};
