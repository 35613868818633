import { useEffect, useRef, useState } from "react";

import DOMPurify from "dompurify";
import { useSelector } from "react-redux";

import { Button, CircularProgress, DialogActions, DialogContent } from "@mui/material";

import { successApiMan } from "@elx-element/common/apiClient/apiMan";
import { useElementContext } from "@elx-element/common/elementContext";
import { Notification } from "@elx-element/common/enums";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { NotificationEventModel } from "@elx-element/common/events/types";
import BaseTextField from "@elx-element/ui/Inputs/BaseTextField";

import { WebContainerClient, WikiResultModel } from "../../types.generated";

import { selectCulture } from "../../store/main/selectors";

import useTexts from "../../hooks/useTexts";

import useStyles from "./styles";

interface ConfluenceContentProps {
  onBack: () => void;
  // document reprezentuje jednu stránku s řešením problému v Confluence
  document: WikiResultModel;
}

const ConfluenceComponent = (props: ConfluenceContentProps) => {
  const texts = useTexts();
  const culture = useSelector(selectCulture);
  const { classes } = useStyles();
  const [comment, setComment] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const { getParsedAuthToken } = useElementContext();

  const abortController = useRef(new AbortController());
  useEffect(
    () => () => {
      abortController.current.abort();
    },
    []
  );

  const sendComment = async (errorPageId: string) => {
    setLoading(true);
    if (comment) {
      await processSendComment(
        errorPageId,
        texts.COMMENT_SENT,
        new WebContainerClient({ abortSignal: abortController.current.signal })
      );
    }
    setLoading(false);
    props.onBack();
  };

  const processSendComment = async (errorPageId: string, sentText: string, client: WebContainerClient) => {
    const accessToken = getParsedAuthToken();
    let preferredUsername: string | undefined;
    if (accessToken) {
      preferredUsername = accessToken.preferred_username;
    }
    const success = await successApiMan(
      client.createConfluenceComment(errorPageId, { user: preferredUsername, comment })
    );
    if (success) {
      dispatchNotificationEvent(new NotificationEventModel(sentText, Notification.success));
    }
  };

  return (
    <>
      <DialogContent className={classes.errorContent}>
        {/* DOMPurify vyčistí HTML kód getnutý z confluence */}
        <div
          // eslint-disable-next-line no-useless-escape, react/no-danger
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.document.html!).replace(/\<p\>\<\/p\>/g, "") }}
          className={classes.confluence}
        />
        <br />
        <BaseTextField
          culture={culture}
          autoFocus
          margin="dense"
          fullWidth
          multiline
          minRows={3}
          label={texts.FEEDBACK}
          value={comment}
          onChange={e => setComment(e.target.value !== "" ? e.target.value : undefined)}
        />
      </DialogContent>
      <DialogActions className={classes.errorFooter}>
        <Button
          onClick={() => {
            props.onBack();
            setComment(undefined);
          }}
          color="primary"
          variant="contained"
        >
          {texts.BACK}
        </Button>
        <Button
          disabled={!comment || loading}
          startIcon={loading ? <CircularProgress size={16} color="inherit" /> : undefined}
          onClick={() => sendComment(props.document.id!)}
          color="secondary"
          variant="contained"
        >
          {texts.SEND}
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfluenceComponent;
